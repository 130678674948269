import { useEffect, useState } from "react";
import Router from "./routes/router";
import "./App.css";
import { SnackbarProvider } from "notistack";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import NetworkStatus from "./hooks/NetworkStatus";
import EnableNotificationPermission from "./notification/EnableNotification";
import ScreenLoader from "./components/ScreenLoader";
import { Box } from "@mui/material";
import { AuthProvider } from "./auth/AuthContext";
import { useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const Theme = createTheme({
    typography: {
      fontFamily: "Public Sans",
      fontWeightLight: "300",
      fontWeightRegular: "400",
      fontWeightMedium: "500",
    },
  });

  useEffect(() => {
    const checkPermissions = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          setIsNotificationModalOpen(false);
        } else {
          console.log("Notification permission denied or not decided.");
          setIsNotificationModalOpen(true);
        }
        setIsAppReady(true);
      });
    };

    checkPermissions();
  }, []);

  const onClose = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        setIsNotificationModalOpen(false);
      } else {
        console.log("Notification permission denied.");
        if (!skipNotificationSetting) {
          setIsNotificationModalOpen(false);
          return;
        }
        setIsNotificationModalOpen(true);
        alert(
          "To enable notifications, please go to your browser settings and allow notifications for this site."
        );
      }
    });
  };
  const [skipNotificationSetting, setskipNotificationSetting] = useState(false);
  const handlePermissionChange = (value: boolean) => {
    console.log("Received value from child:", value); // Log the value
    setskipNotificationSetting(value); // Update the state with true/false
  };

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2500}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MuiThemeProvider theme={Theme}>
          <main className="MainPages">
            {isAppReady ? (
              <>
                <NetworkStatus />
                <Router />
                <EnableNotificationPermission
                  open={isNotificationModalOpen}
                  onClose={onClose}
                  onPermissionChange={handlePermissionChange}
                />
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                width="100vw"
                bgcolor="#f5f5f5"
              >
                <ScreenLoader />
              </Box>
            )}
          </main>
        </MuiThemeProvider>
      </SnackbarProvider>
    </>
  );
}

export default App;
