// These are the protected Routes pages for handling or providing safe accessibility for applications
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AuthGaurd from "src/auth/AuthGaurd";
import GuestGaurd from "src/auth/GuestGaurd";
import { useEffect } from "react";
import { useAuthContext } from "src/auth/useAuthContext";
import { handleProfileStatus } from "./profilePath/profileRoute";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuthContext();

  useEffect(() => {
    isAuthenticated
      ? handleProfileStatus(user?.profileStatus, navigate)
      : navigate("/login");
  }, []);

  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default ProtectedRoutes;
