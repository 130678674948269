import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";
import { handleProfileStatus } from "src/routes/profilePath/profileRoute";

function AuthGaurd({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { isAuthenticated, isInitialize, user } = useAuthContext();

  if (!isInitialize) {
    return <h1>Loading</h1>;
  }

  if (!isAuthenticated) {
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
}

export default AuthGaurd;
