import { useNavigate } from "react-router-dom"; // For navigate type

export const handleProfileStatus = (profileStatus: any, navigate: any) => {
  console.log("yee issue hai kya ", profileStatus);
  const statusRoutes: { [key: number]: string } = {
    0: "/verify", // Verify
    1: "/auth/onboardingpays", // Proceed to checkout
    2: "/auth/userkyc", // GST process
    3: "/auth/upload-files", // Upload GST documents
    4: "/auth/verifyaadhar", // Verify Aadhaar
    5: "/auth/verifypan", // Verify PAN
    6: "/auth/panuploadfiles", // PAN upload documents
    7: "/auth/approval", // Document approval
    8: "/dashboard/main", // Final successful status
  };

  const route = statusRoutes[profileStatus];
  if (route) {
    localStorage.setItem("isLogedIn", "true");
    navigate(`${route}`); // Navigate to the mapped route
  } else {
    console.error("Invalid profile status:", profileStatus);
  }
};
