import { createContext, useCallback, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ApiService from "src/api/api";

type AuthContextTypes = {
  isInitialize: boolean;
  isAuthenticated: boolean;
  isLicensed: boolean;
  user: {
    profileStatus: number;
    _id: string;
  };
  login: () => void;
  logout: () => void;
  initialize: () => void;
};

export const AuthContext = createContext<AuthContextTypes | null>(null);

const initialState = {
  isLicensed: true,
  isInitialize: false,
  isAuthenticated: false,
  user: null,
};

function reducer(state: any, action: any) {
  if (action.type === "login") {
    return {
      ...state,
      isLicensed: true,
      isInitialize: true,
      isAuthenticated: true,
      user: action.payload,
    };
  } else if (action.type === "logout") {
    return {
      ...state,
      isLicensed: true,
      isInitialize: true,
      isAuthenticated: false,
      user: null,
    };
  } else if (action.type === "license_not_found") {
    return {
      ...state,
      isLicensed: false,
      isInitialize: true,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await ApiService.setToken(token);
        const Response = await ApiService.get("/auth/profileStatus");
        if (Response.status === 200) {
          if (Response.data.Active) {
            dispatch({
              type: "login",
              payload: Response.data.result,
            });
          }
        } else {
          logout();
          throw new Error();
        }
      } catch (err) {
        logout();
      }
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = () => {
    initialize();
  };

  const logout = () => {
    localStorage.removeItem("token");
    dispatch({
      type: "logout",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        initialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
