import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { StyledEngineProvider } from "@mui/material/styles";
import { ProSidebarProvider } from "react-pro-sidebar";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth/AuthContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
      alert("Service Worker failed to register. Please check the setup.");
    });
}
root.render(
  <Provider store={store}>
    <AuthProvider>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ProSidebarProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ProSidebarProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </AuthProvider>
  </Provider>
);
