import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routerType } from "../types/router.types";
import { authrouterData, dashboardRoutesData } from "./routerData";
import ProtectedRoutes from "./ProtectedRoutes";
import React, { Suspense, useEffect, useState } from "react";
import { checkloginAuthentication } from "./loginPath";
import { handleProfileStatus } from "./profilePath/profileRoute";
import { Box } from "@mui/material";
import ScreenLoader from "src/components/ScreenLoader";
import LoadingScreen from "src/components/loadingScreen";
import AuthGaurd from "src/auth/AuthGaurd";
import GuestGaurd from "src/auth/GuestGaurd";

// Lazy load components
const Login = React.lazy(() => import("../pages/login/Login"));
const Registor = React.lazy(() => import("../pages/Signup/Register"));
const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));
const OtpVerification = React.lazy(
  () => import("../pages/Signup/verifyOtp/OtpVerification")
);
const ForgetPassword = React.lazy(
  () => import("../pages/login/ForgetPassword")
);
const DashboardProtectedRoutes = React.lazy(
  () => import("../routes/DashboardRoutes")
);
const TwoStepVerification = React.lazy(
  () => import("../pages/login/TwoStepVerification")
);
const PublicRoute = React.lazy(() => import("./PublicRoutes"));

const Router = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [profileStatus, setProfileStatus] = useState<any | null>(null);

  // Map routes for authentication and dashboard
  const pageRoutes = authrouterData.map(
    ({ path, title, element }: routerType) => (
      <Route key={title} path={`/${path}`} element={element} />
    )
  );

  const DashboardRoutes = dashboardRoutesData.map(
    ({ path, title, element }: routerType) => (
      <Route key={title} path={`/${path}`} element={element} />
    )
  );

  const navigate = useNavigate();
  // useEffect(() => {
  //   const fetchProfileStatus = async () => {
  //     if (token && profileStatus === null) {
  //       const response: any = await checkloginAuthentication(token);
  //       if (response?.success) {
  //         const statusCount = response.profileStatus;
  //         setProfileStatus(statusCount);
  //         const currentPath = location.pathname;

  //         // Navigate only if profileStatus requires it
  //         if (statusCount >= 0) {
  //           // Ensure the user stays on the current path if it's valid
  //           if (
  //             currentPath !== "/dashboard/main" &&
  //             !currentPath.includes("/dashboard") // Adjust based on your dashboard structure
  //           ) {
  //             handleProfileStatus(statusCount, navigate);
  //           }
  //         }
  //       }
  //     }
  //   };

  //   fetchProfileStatus();
  // }, [token, profileStatus, navigate, location.pathname]);

  return (
    <Suspense
      fallback={
        // <Box
        //   display="flex"
        //   justifyContent="center"
        //   alignItems="center"
        //   height="100vh"
        //   width="100vw"
        //   bgcolor="#f5f5f5" // Optional background color
        // >
        //   <ScreenLoader />
        // </Box>
        <LoadingScreen />
      }
    >
      <Routes>
        {/* Protected Routes for Dashboard */}
        <Route
          path="/dashboard"
          element={
            <AuthGaurd>
              <DashboardProtectedRoutes />
            </AuthGaurd>
          }
        >
          {DashboardRoutes}
        </Route>

        {/* Public Routes */}
        <Route path="/" element={<PublicRoute />}>
          <Route path="" element={<Registor />} />
          <Route path="login" element={<Login />} />
          <Route path="verify" element={<OtpVerification />} />
          <Route path="authentication" element={<TwoStepVerification />} />
          <Route path="forget" element={<ForgetPassword />} />
        </Route>
        <Route path="/auth" element={<ProtectedRoutes />}>
          {pageRoutes}
        </Route>

        {/* Fallback Route */}
        <Route path="*" element={<PageNotFound />} />
        <Route path="/auth/" element={<PageNotFound />} />
        <Route path="/dashboard/" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
